import React from "react";

import { graphql, useStaticQuery, Link } from "gatsby";

import {
  Container,
  Content,
  Description,
  Career,
  Avatar,
  CareerImgs,
  CareerImg,
  Title,
  ImgBox,
  BgImage,
  CareerImgsSlider,
} from "./styles";

function About() {
  const {
    avatar,
    amba,
    fmusp,
    mcMasterUniversity,
    sedes,
    servidorPublido,
    sirio,
    banner,
    arizona,
  } = useStaticQuery(graphql`
    fragment squareImage on File {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    query {
      banner: file(relativePath: { eq: "bgImage/bg-about.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      avatar: file(relativePath: { eq: "avatar.jpg" }) {
        ...squareImage
      }

      amba: file(
        relativePath: { eq: "about/associacao_medica_brasileira_de_acupuntura_amba.png" }
      ) {
        ...squareImage
      }
      chinaAcadenyOfChineseMedical: file(
        relativePath: { eq: "about/china_acadeny_of_chinese_medical.png" }
      ) {
        ...squareImage
      }
      fmusp: file(
        relativePath: { eq: "about/faculdade_de_medicina_da_universidade_de_sao_paulo.png" }
      ) {
        ...squareImage
      }
      mcMasterUniversity: file(relativePath: { eq: "about/mc_master_university.png" }) {
        ...squareImage
      }
      sedes: file(relativePath: { eq: "about/instituto_sedes_sapientiae.png" }) {
        ...squareImage
      }
      servidorPublido: file(relativePath: { eq: "about/hospital_servidor_publico_municipal.png" }) {
        ...squareImage
      }
      sirio: file(relativePath: { eq: "about/hospital_sirio_libanes.png" }) {
        ...squareImage
      }
      arizona: file(relativePath: { eq: "about/the_university_of_arizona.png" }) {
        ...squareImage
      }
    }
  `);
  return (
    <Container>
      <ImgBox>
        <BgImage fluid={banner.childImageSharp.fluid}>
          <Title>Sobre</Title>
        </BgImage>
      </ImgBox>
      <Content>
        <Description>
          <div>
            <p>
              <strong>Dra Helena Campiglia</strong> é médica integrativa, clínica geral e
              acupunturista formada pelo Hospital das Clínicas da Universidade de São Paulo,
              especilizada em avaliação e tratamento de dor e em distúrbios da mulher
              (infertilidade, gestação e menopausa).
            </p>
            <p>
              Professora de <Link to="/acupuntura">acupuntura</Link> e{" "}
              <Link to="/medicina-tradicional-chinesa">Medicina Tradicional Chinesa</Link>, atua
              nesta área há mais de 20 anos aliando a clínica médica ao tratamento holístico
              proposto pela Medicina Chinesa, do qual a acupuntura faz parte. Atualmente a Dra
              Helena Campiglia faz parte do programa de Medicina Integrativa da Universidade do
              Arizona nos EUA e atua como médica clínica e integrativa.
            </p>
          </div>
          <div>
            <Avatar fluid={avatar.childImageSharp.fluid} />
          </div>
        </Description>

        <Career>
          <ul>
            <li>Médica formada pela Universidade de São Paulo</li>
            <li>
              Residência em Clínica Médica no Hospital das Clínicas da Universidade de São Paulo
            </li>
            <li>
              Especialização em avaliação e tratamento Interdisciplinar de DOR pela Faculdade de
              Medicina da Universidade de São Paulo
            </li>
            <li>
              Extensão em Ginecologia Endócrina e Climatério pelo Instituto de Ensino e Pesquisa do
              Hospital Sírio Libanês
            </li>
            <li>Pós-graduada em Psicologia Analítica pelo Instituto Sedes Sapientiae</li>
            <li>
              Formada em Acupuntura e Medicina Chinesa pelo Hospital do Servidor Público Municipal e
              pela Sociedade Médica de Acupuntura de São Paulo (SOMA-SP) e pós-graduação e estágio
              hospitalar na Academia de Medicina Chinesa em Pequim
            </li>
            <li>
              Docente de Acupuntura e Medicina Chinesa da Associação Médica Brasileira de Acupuntura
              (AMBA)
            </li>
            <li>
              Docente de Acupuntura e Medicina Chinesa da Universidade McMaster, Ontário, Canadá no
              Programa Avançado de Acupuntura Médica
            </li>
            <li>
              AUTORA DOS LIVROS:
              <ul>
                <li>Psique e Medicina Tradicional Chinesa (3ª edição, Ícone Editora)</li>
                <li>
                  Domínio do Yin, da Fertilidade à Maternidade: A Mulher e suas Fases na Medicina
                  Tradicional Chinesa (2a edição, Ícone Editora).
                </li>
              </ul>
            </li>
          </ul>
        </Career>
        <CareerImgsSlider>
          <CareerImgs>
            <CareerImg fluid={amba.childImageSharp.fluid} />
            <CareerImg fluid={fmusp.childImageSharp.fluid} />
            <CareerImg fluid={mcMasterUniversity.childImageSharp.fluid} />
            <CareerImg fluid={sedes.childImageSharp.fluid} />
            <CareerImg fluid={servidorPublido.childImageSharp.fluid} />
            <CareerImg fluid={sirio.childImageSharp.fluid} />
            <CareerImg fluid={arizona.childImageSharp.fluid} />
          </CareerImgs>
        </CareerImgsSlider>
      </Content>
    </Container>
  );
}

export default About;
