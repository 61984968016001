import React from "react";
/* eslint-disable max-len */

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import About from "../components/template-parts/About";

const Sobre = () => (
  <Layout>
    <SEO
      title="Sobre"
      keywords="helena campiglia, helenacampiglia, medica clinica geral, medicaclinicageral, acupuntura, infertilidade, mente e corpo, meditação, fmusp"
      description="Médica clínica geral e acupunturista, especilizada em avaliação e tratamento de dor e em distúrbios da mulher (infertilidade, gestação e menopausa)."
    />
    <About />
  </Layout>
);

export default Sobre;
